import React from 'react';

const Oracle = ({ fill, className }) => {
  return (
    <div className={className}>
      <svg width="133" height="17" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M57.081 11.06h8.737l-4.62-7.351-8.479 13.29h-3.858L59.174 1.036A2.445 2.445 0 0161.198 0c.802 0 1.55.376 1.984 1.008L73.536 17h-3.859l-1.82-2.97H59.01l-1.93-2.97zm40.084 2.97V.16h-3.274v15.226c0 .417.163.82.475 1.129.313.309.734.484 1.196.484h14.933l1.93-2.97h-15.26zM42.99 11.543c3.18 0 5.762-2.54 5.762-5.684 0-3.145-2.582-5.698-5.762-5.698H28.665V17h3.273V3.131h10.835a2.734 2.734 0 012.745 2.729 2.733 2.733 0 01-2.745 2.727l-9.231-.013L43.317 17h4.756l-6.577-5.456h1.495zM8.514 17C3.814 17 0 13.237 0 8.587S3.814.161 8.514.161h9.896c4.701 0 8.513 3.776 8.513 8.426S23.11 17 18.41 17H8.514zm9.676-2.97c3.045 0 5.512-2.433 5.512-5.443s-2.467-5.456-5.512-5.456H8.733c-3.044 0-5.511 2.446-5.511 5.456 0 3.01 2.467 5.443 5.511 5.443h9.457zM80.37 17c-4.7 0-8.519-3.763-8.519-8.413S75.67.161 80.371.161h11.754l-1.917 2.97h-9.62c-3.043 0-5.516 2.446-5.516 5.456 0 3.01 2.473 5.443 5.516 5.443h11.808L90.466 17H80.371zm40.044-2.97c-2.513 0-4.647-1.666-5.299-3.965h13.996l1.929-2.97h-15.925c.652-2.284 2.786-3.964 5.3-3.964h9.606l1.943-2.97h-11.767c-4.702 0-8.52 3.776-8.52 8.426s3.818 8.412 8.52 8.412h10.096l1.929-2.97h-11.808z"
          fill={fill}
          fill-rule="nonzero"
        />
      </svg>
    </div>
  );
};

export default Oracle;
